/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Loader } from "r10-source-library";

const LoadingWrapper = styled.div`
  position: fixed;
  height: 100vh;
  background-color: white;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: ${(props) => (props.show ? "1000" : "-1")};

  & [class^="Loaderstyle__Loader-sc-1hfrhgh-0"] {
    position: relative;
    top: 160px;
  }
`;

const translation = {
  en: "Loading your data",
  el: "Γίνεται επεξεργασία των δεδομένων",
  es:"Un momento por favor ..."
};

const LoadingIndicator = ({ isLoading, selectedLanguage, isError }) => {
  // handle remove page scroll from loading & error modal
  useEffect(() => {
    document.body.style.overflow =
      (isLoading && !isError) || (isError && !isLoading) ? "hidden" : "auto";
  }, [isLoading, isError]);
  if (!isLoading) return null;

  return (
    <>
      <LoadingWrapper show={isLoading} data-testid="loader">
        <Loader srText="Loading" text={translation[selectedLanguage || "en"]} />
      </LoadingWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.config.isLoading,
    selectedLanguage: state.language.language,
    isError: state["error-modal"] && state["error-modal"].isOpen,
  };
};

export default connect(mapStateToProps)(LoadingIndicator);
