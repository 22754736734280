import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  all,
  take,
} from "redux-saga/effects";
import {
  CONFIG_REQUEST,
  CONFIG_REQUEST_FAIL,
  CONFIG_REQUEST_SUCCESS,
  SET_PAGE_CONFIG,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  SEND_TAGS,
  setGlobalLoading,
  setTaggingSheet,
} from "actions/configActions";
import { setError } from "actions";
import {
  LOGGED_IN_SUCCESSFULLY,
  SET_IS_AUTH_APP,
  CHECK_SAML_AUTH,
  AUTHORIZED_SAML,
} from "actions/authenticationActions";
import { isAuthSelector } from "selectors/authSelector";
import {
  analyticsSelector,
  tealiumConfigSelector,
} from "selectors/analyticsSelector";
import configService from "services/ConfigService";
import {
  LANGUAGE_REQUEST,
  LANGUAGE_LIST_REQUEST_SUCCESS,
  LANGUAGE_LIST_REQUEST_FAIL,
} from "actions/languageActions";
import { PAGE_MOUNTED } from "actions/applicationActions";

import TranslationService from "services/TranslationService";
import { logout } from "services/Liveness";

function* configWorker() {
  let newConfig;
  const isAuthenticatedApp = yield select(
    (state) => state?.authentication?.isAuthApp
  );
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (auth || !isAuthenticatedApp) {
    yield put(setGlobalLoading(true));
  }
  const configItemJSON = localStorage.getItem("config");
  const configItem = configItemJSON && JSON.parse(configItemJSON);
  if (
    configItem &&
    Date.now() < configItem.fetchDate + configItem.global.TTL * 1000
  ) {
    newConfig = configItem;
    yield put(setGlobalLoading(false));
    yield put({
      type: CONFIG_REQUEST_SUCCESS,
      payload: configItem,
    });
    if (Object.keys(configItem.pages).length) {
      yield put({
        type: SET_PAGE_CONFIG,
        payload: configItem.pages,
      });
    }
  } else {
    const defaultAppLanguage = configItem?.global?.defaultAppLanguage;
    if (defaultAppLanguage) {
      yield put({
        type: LANGUAGE_REQUEST,
        payload: defaultAppLanguage,
      });
    }

    try {
      const data = yield call(configService.fetchConfig);
      newConfig = data;
      if (!localStorage.getItem("lang"))
        localStorage.setItem("lang", newConfig?.global?.defaultAppLanguage);
      yield put({
        type: CONFIG_REQUEST_SUCCESS,
        payload: data,
      });
      if (Object.keys(data.pages).length) {
        yield put({
          type: SET_PAGE_CONFIG,
          payload: data.pages,
        });
      }
    } catch (error) {
      yield put({ type: CONFIG_REQUEST_FAIL, error });
      yield put(
        setError(error.response?.status || 500, { type: CONFIG_REQUEST })
      );
      logout();
    } finally {
      yield put(setGlobalLoading(false));
    }
  }
  try {
    const { data } = yield call(TranslationService.fetchLanguages);
    yield put({
      type: LANGUAGE_LIST_REQUEST_SUCCESS,
      payload: {
        languages: data,
      },
    });

    const defaultAppLanguage =
      localStorage.getItem("lang") || newConfig.global.defaultAppLanguage;
    const languagesState = yield select((state) => state.language.languages);
    const languages = Object.keys(languagesState || {});
    const languageIndex = languages.findIndex(
      (lang) => defaultAppLanguage === lang
    );
    const language = languageIndex === -1 ? "en" : defaultAppLanguage;

    if (language)
      yield put({
        type: LANGUAGE_REQUEST,
        payload: language,
      });
  } catch (error) {
    yield put({ type: LANGUAGE_LIST_REQUEST_FAIL, error });
  }
}

function* configWatcher() {
  yield takeLatest(CONFIG_REQUEST, configWorker);
}

function* samlAuthWorker({ payload }) {
  try {
    const status = yield call(configService.checkSamlAuthorization, payload);
    yield put({
      type: AUTHORIZED_SAML,
      payload: {
        status,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* samlAuthWatcher() {
  yield takeLatest(CHECK_SAML_AUTH, samlAuthWorker);
}

function* handleTealiumCalling({ type, payload }) {
  const taggingId = payload.taggingId;
  let taggingValue = null;
  let taggingType = "";
  if (type === PAGE_MOUNTED) {
    taggingType = "view";
    taggingValue = {
      page_name: payload.pageTitle,
      tagging_id: payload.taggingId,
    };
  } else {
    taggingType = payload.taggingType;
    taggingValue = { ...payload.taggingValue };
  }

  yield generateTagToSend(taggingId, taggingType, taggingValue);
}

function* generateTagToSend(taggingId, taggingType, taggingValue) {
  const { taggingSheet } = yield select(analyticsSelector);
  const taggingCriteria = taggingId || taggingValue;

  let tag = taggingSheet.find((obj) => obj["tagging_id"] === taggingCriteria);
  tag = { ...tag, ...taggingValue };
  if (!window.utag) {
    const script = document.getElementById("utagScript");
    if (script) {
      script.addEventListener("load", () => {
        console.log("UTAG:", window.utag[taggingType](tag));
      });
    }
  } else {
    console.log("UTAG:", window.utag[taggingType](tag));
  }
}

function* sendTealiumTagsWatcher() {
  try {
    yield takeLatest(PAGE_MOUNTED, handleTealiumCalling);
    yield takeEvery(SEND_TAGS, handleTealiumCalling);
  } catch (error) {
    console.error("sendTealiumTagsWatcher error: ", error);
  }
}
export function* getTaggingSheetWatcher() {
  try {
    yield takeLatest(CONFIG_REQUEST_SUCCESS, getTaggingSheet);
  } catch (error) {
    console.error(error);
  }
}

export function* getTaggingSheet() {
  const tealiumConfig = yield select(tealiumConfigSelector);
  let taggingSheet = [];
  if (
    tealiumConfig?.length > 0 &&
    tealiumConfig[0]?.tealiumIsEnabled &&
    tealiumConfig[0]?.taggingSheetJson
  ) {
    taggingSheet = tealiumConfig[0]?.taggingSheetJson;
  } else if (
    tealiumConfig?.length > 0 &&
    tealiumConfig[0]?.tealiumIsEnabled &&
    tealiumConfig[0]?.taggingSheetUrl
  ) {
    taggingSheet = yield call(
      configService.fetchTaggingSheet,
      tealiumConfig[0]?.taggingSheetUrl
    );
  }
  yield put(setTaggingSheet(taggingSheet));
}

const configSaga = [
  configWatcher(),
  samlAuthWatcher(),
  sendTealiumTagsWatcher(),
  getTaggingSheetWatcher(),
];

export default configSaga;
