import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading, Paragraph, Button, Modal, SimpleGrid as Grid } from 'r10-source-library';

import Liveness, { logout } from '../../services/Liveness';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import { useSelector } from 'react-redux';

const Div = styled.div`
  text-align: center;
`;


const translation = {
  en: {
    title: 'Session time-out',
    body: 'Your session will expire and automatically log out in',
    continue: 'Continue',
    logout: 'Log out',
    footer: 'Press continue to continue your session, or log out to end.',
    minutes: 'minutes',
    minute: 'minute',
    seconds: 'seconds',
    second: 'second',
  },
  el: {
    title: 'Συνεδρία σε αδράνεια',
    body: 'Η συνεδρία λήγει και θα αποσυνδεθείς αυτόματα σε',
    continue: 'Συνέχεια',
    logout: 'Αποσύνδεση',
    footer: 'Επίλεξε "Συνέχεια" εάν θέλεις να παραμείνεις συνδεδεμένος ή "Αποσύνδεση" εάν θέλεις να αποσυνδεθείς άμεσα.',
    minutes: 'λεπτά',
    minute: 'λεπτό',
    seconds: 'δευτερόλεπτα',
    second: 'δευτερόλεπτο',
  }
};


const InactivityWarning = () => {
  const [timeLeft, setTimeLeft] = useState();
  const selectedLanguage = useSelector((state) => state.language.language);

  const calcuateTimeLeft = useCallback((millisecondsRemaining)=> {
    const minutes = Math.floor((millisecondsRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((millisecondsRemaining % (1000 * 60)) / 1000);
    const minutesString = minutes === 1 ? translation[selectedLanguage || "en"].minute : translation[selectedLanguage || "en"].minutes;
    const secondsString = seconds === 1 ? translation[selectedLanguage || "en"].second : translation[selectedLanguage || "en"].seconds;
    if (millisecondsRemaining < 0) {
      Liveness.checkTimeout();
      return `0 ${minutesString} 0 ${secondsString}`;
    }
    return `${minutes} ${minutesString} ${seconds} ${secondsString}`;
  }, [selectedLanguage])

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTimeLeft(calcuateTimeLeft(Liveness.screenLockTimeout - Liveness.getInactivityDuration()));
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);
  return (
    <Modal
      srName="test-modal"
      isOpen
      size={4}
      animate
      isClosable
      onCloseCb={() => {
        Liveness.update(true);
      }}
    >
      <Grid columns={{ sm: 1 }} justify="center" spacing={3} verticalAlign="center">
        <Div>
          <ClockIcon />
        </Div>
        <Heading size={1} level={5} justify="center" weight={3} text={
          translation[selectedLanguage || "en"].title
        } />
        <Paragraph justify="center" size={2}>
          {translation[selectedLanguage || "en"].body}
        </Paragraph>
        <Paragraph justify="center" weight={3} size={2} bold>
          {timeLeft}
        </Paragraph>
        <Paragraph justify="center" size={2}>
          {translation[selectedLanguage || "en"].footer}
        </Paragraph>
        <Grid columns={{ sm: 2 }} spacing={3} justify="center" layout={{ sm: [12], lg: [3] }}>
          <Div />
          <Div>
            <Button text={
              translation[selectedLanguage || "en"].continue
            } appearance="primary" onClick={() => Liveness.update(true)} />
          </Div>
          <Div>
            <Button text={
              translation[selectedLanguage || "en"].logout
            } appearance="alt1" onClick={() => logout()} />
          </Div>
          <Div />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default InactivityWarning;
